// import { Config, getHttpEndpoint, Network } from '@orbs-network/ton-access'
// import TronWeb from 'tronweb'
// import { getTransactionHash, pollForMethods } from '@/api/chain'
// import { CenterSubmitResult } from '@/api/type'
// import { DexTransaction } from '@/constants/types'
// import {
//   sendRawTransactionByCenterApi,
//   sendTransactionByCenterApi,
//   SendTransactionByCenterParamsType
// } from '@/hooks/api/chain'
// import { UserType } from '@/stores/userStore/type'
// import { getChainByChainId } from '@/stores/walletStore/utils'
import { DexRouteInfo, DexTransaction } from '@/constants/types'
import { sendRawTransactionByCenterApi } from '@/hooks/api/chain'
import { trc20Triggersmartcontract } from '@/api/tron'
import { erc20Abi, formatUnits, parseUnits } from 'viem'
import dayjs from 'dayjs'

// export const mockTronChainId = 728126428
export const mockTronChainId = 19484
export const mockStableTronChainId = 1000
export const tronDecimals = 6
export const mockTronOKXChainId = 195 // this chainId for okx api
export const OkxChainIdToMockIdMap = {
  [mockTronOKXChainId]: mockTronChainId
}
export const MockIdToOkxChainIdMap = {
  [mockTronChainId]: mockTronOKXChainId
}
export const tronScanUrl = 'https://tronscan.org/#/transaction/'
export const tronTokenScanUrl = 'https://tronscan.org/#/token20/'
// 19484
// https://api.trongrid.io
export async function getTronWebProvider() {
  const { default: TronWeb } = await import('tronweb')
  return new TronWeb({
    // fullHost: 'https://api.trongrid.io'
    fullHost:
      'https://orbital-dimensional-season.tron-mainnet.quiknode.pro/d14878573fe23f8f40621a303ee2eaa3c812ba1c'
    // 'https://rpc.ankr.com/premium-http/tron/3b62cdcc3f724166df4c53366e9acad6ccfab7299c589a1e17f000a0c5aac75'
    // headers: { 'TRON-PRO-API-KEY': import.meta.env.VITE_TRON_API_KEY }
  })
}

export async function getBalance({
  account,
  token
}: {
  account: string
  token?: string
}): Promise<{
  value: bigint
  formatted: string
  decimals: number
  symbol: string
  token: string | undefined
  name?: string | undefined
}> {
  const tronWeb = await getTronWebProvider()
  tronWeb.setAddress(account)
  let balance: bigint
  let decimals: number
  let symbol: string
  let name: string
  if (token) {
    const contract = await tronWeb.contract(erc20Abi, token)
    const balanceResponse = await contract.balanceOf(account).call()
    balance = BigInt(balanceResponse)
    const decimalsResponse = await contract.decimals().call()
    decimals = parseInt(decimalsResponse)
    symbol = await contract.symbol().call()
    name = await contract.name().call()
  } else {
    const accountInfo = await tronWeb.trx.getAccount(account)
    balance = BigInt(accountInfo?.balance || '0')
    symbol = 'TRX'
    decimals = tronDecimals
    name = 'TRX'
  }

  const formatted = formatUnits(balance, decimals)
  return {
    value: balance,
    formatted: formatted,
    decimals: decimals,
    symbol: symbol,
    token: token,
    name
  }
}

export async function sendTransaction({
  amount,
  from,
  to,
  contract
}: {
  amount: bigint
  from: string
  to: string
  contract?: string
}) {
  const tronWeb = await getTronWebProvider()
  tronWeb.setAddress(from)
  let tx: any
  if (contract) {
    const functionSelector = 'transfer(address,uint256)'
    const res = await tronWeb.transactionBuilder.triggerSmartContract(
      contract,
      functionSelector,
      {},
      [
        { type: 'address', value: to },
        { type: 'uint256', value: amount.toString() }
      ]
    )
    if (res.result) {
      tx = res.transaction
    }
  } else {
    tx = await tronWeb.transactionBuilder.sendTrx(to, amount.toString(), from)
  }

  return tx
  // const signedTx = await tronWeb.trx.sign(tx.transaction)
  // const result = await tronWeb.trx.sendRawTransaction(signedTx)
}

export const pushTronTx = async ({
  rawTransaction,
  apiParams
}: {
  rawTransaction: any
  apiParams?: {
    user: any
    type: string
    params: DexTransaction
  }
}) => {
  if (apiParams && rawTransaction) {
    const hash = await sendRawTransactionByCenterApi({
      apiParams,
      callData: rawTransaction.raw_data_hex,
      tx: '',
      extParams: [JSON.stringify(rawTransaction.raw_data)]
    })
    if (hash) return hash
  }

  const hash: {
    result: boolean
    txid: string
    transaction: any
  } = await sendRawTransaction({
    rawTransaction: rawTransaction
  })

  return hash.txid
}

export async function sendRawTransaction({
  rawTransaction
}: {
  rawTransaction: any
}) {
  const tronWeb = await getTronWebProvider()
  // tronWeb.setAddress(from)
  const result = await tronWeb.trx.sendRawTransaction(rawTransaction)
  return result
}

export async function getTransaction(txId: string): Promise<any> {
  const tronWeb = await getTronWebProvider()

  try {
    const transaction = await tronWeb.trx.getTransaction(txId)
    return transaction
  } catch (error) {
    console.error(`Failed to fetch transaction by ID ${txId}:`, error)
    throw error
  }
}

export async function getTxFee({
  token,
  from,
  amount,
  to
}: {
  token: string | undefined
  from: string
  amount: bigint
  to: string
}): Promise<
  | {
      result: {
        result: boolean
      }
      energy_used: number
      constant_result: string[]
      logs: {
        address: string
        data: string
        topics: string[]
      }[]
      transaction: {
        ret: any[]
        visible: boolean
        txID: string
        raw_data: any
        raw_data_hex: string
      }
    }
  | undefined
> {
  const tronWeb = await getTronWebProvider()

  try {
    if (token) {
      const energyEstimate =
        await tronWeb.transactionBuilder.triggerConstantContract(
          token,
          'transfer(address,uint256)',
          {
            feeLimit: 150_000_000,
            callValue: 0
          },
          [
            {
              type: 'address',
              value: to
            },
            {
              type: 'uint256',
              value: amount.toString()
            }
          ],
          from
        )

      return energyEstimate
    } else {
      const bandwidth = await tronWeb.trx.getBandwidth(from)
      const a = await tronWeb.trx.getBandwidthPrices()
      const f = await tronWeb.trx.getAccountResources(from)
      console.log({
        bandwidth,
        a,
        f
      })
    }
  } catch (error) {
    console.error('Error estimating energy:', error)
    throw error
  }
}

export async function trc20TriggerConstantContract({
  owner,
  toAddress,
  callValue,
  parameter,
  functionSelector
}: {
  owner: string
  callValue: number
  toAddress: string
  parameter: string
  functionSelector: string
}): Promise<{
  result: any
}> {
  try {
    const result = await trc20Triggersmartcontract({
      owner_address: owner,
      contract_address: toAddress,
      function_selector: functionSelector,
      parameter: parameter,
      call_value: callValue,
      fee_limit: 300000000,
      visible: true
    })
    return result.data?.transaction
  } catch (error) {
    console.error('Error in triggerConstantContract:', error)
  }
}

export async function createTrc20ApproveTransaction({
  owner,
  token,
  amount,
  spender
}: {
  owner: string
  token: string
  amount: string
  spender: string
}) {
  try {
    const tronWeb = await getTronWebProvider()

    const approveData = await tronWeb.transactionBuilder.triggerSmartContract(
      token,
      'approve(address,uint256)',
      {},
      [
        {
          type: 'address',
          value: spender
        },
        {
          type: 'uint256',
          value: amount.toString()
        }
      ],
      owner
    )
    return approveData?.transaction
  } catch (error) {
    console.error('Error in createTrc20ApproveTransaction:', error)
    throw error
  }
}

export async function getTrc20Allowance({
  token,
  owner,
  spender
}: {
  token: string
  owner: string
  spender: string
}): Promise<{
  value: bigint
}> {
  try {
    const tronWeb = await getTronWebProvider()
    tronWeb.setAddress(owner)
    const contract = await tronWeb.contract(erc20Abi, token)
    const allowanceResponse = await contract.allowance(owner, spender).call()
    return allowanceResponse
  } catch (error) {
    console.error('allowanceResponse Error getting allowance:', error)
  }
}

function compressPath(path: string[]) {
  const versions: string[] = []
  const lengths: number[] = []

  let i = 0
  while (i < path.length) {
    const currentVersion = path[i]
    versions.push(currentVersion)

    let count = i === 0 ? 2 : 1
    while (i + 1 < path.length && path[i] === path[i + 1]) {
      count++
      i++
    }

    lengths.push(count)
    i++
  }

  return {
    versions,
    lengths
  }
}

export async function sunSwapExactInput(
  bestRouteInfo: DexRouteInfo,
  toAddress: string,
  fromDecimals: number,
  toDecimals: number,
  owner: string,
  callValue: number,
  slippage: number
) {
  const tronWeb = await getTronWebProvider()
  tronWeb.setAddress(owner)

  try {
    const originRoute = JSON.parse(bestRouteInfo.extraString || '')
    const { versions, lengths } = compressPath(originRoute.poolVersions)

    const addressToHex = (address: string) => {
      try {
        if (!address) return ''
        return tronWeb.address.toHex(address).replace(/^41/, '0x')
      } catch (error) {
        console.error('Error converting address to hex:', error)
        return ''
      }
    }

    const args = [
      originRoute.tokens.map(addressToHex),
      versions,
      lengths.map(String),
      originRoute.poolFees.map(String),
      [
        parseUnits(originRoute.amountIn, fromDecimals).toString(),
        parseUnits(
          String(Number(originRoute.amountOut) * (1 - slippage / 100)),
          toDecimals
        ).toString(),
        addressToHex(toAddress),
        dayjs().add(600, 'second').unix().toString()
      ]
    ]

    const abi = {
      inputs: [
        { type: 'address[]', name: 'path' },
        { type: 'string[]', name: 'poolVersion' },
        { type: 'uint256[]', name: 'versionLen' },
        { type: 'uint24[]', name: 'fees' },
        {
          components: [
            { type: 'uint256', name: 'amountIn' },
            { type: 'uint256', name: 'amountOutMin' },
            { type: 'address', name: 'to' },
            { type: 'uint256', name: 'deadline' }
          ],
          type: 'tuple',
          name: 'data'
        }
      ],
      name: 'swapExactInput',
      type: 'function'
    }

    const parameter = tronWeb.utils.abi.encodeParamsV2ByABI(abi, args)

    const functionSelector =
      'swapExactInput(address[],string[],uint256[],uint24[],(uint256,uint256,address,uint256))'
    const res = await tronWeb.transactionBuilder.triggerSmartContract(
      'TCFNp179Lg46D16zKoumd4Poa2WFFdtqYj',
      functionSelector,
      {
        feeLimit: 150000000,
        callValue: tronWeb.toSun(callValue),
        rawParameter: parameter
      },
      [],
      owner
    )

    return res
  } catch (error) {
    console.error('Error in sunSwapExactInput:', error)
    return undefined
  }
}
