export interface IQuoteParams {
  code: number
  fiat: string
  network: string
  payCode: string
  side: QuoteSide
  size: string
  symbol: string
}

export enum QuoteSide {
  BUY = '0',
  SELL = '1'
}

export interface QuoteResponse {
  data: QuoteRes | undefined
  returnCode: string
  returnMsg: string
}

export interface PriceResponse {
  code: string
  data: {
    data: PriceRes[] | undefined
    code: string
    msg: string
  }
  msg: string
}

export interface PriceRes {
  code: SupportPayWay
  fiat: string
  fiatSize: string
  networkFee: string
  platformFee: string
  price: string
  size: string
  symbol: string
}

export interface QuoteRes {
  cryptoPrice: string
  rampFee: string
  crypto: string
  fiatQuantity?: string
  fiat: string
  cryptoQuantity?: string
  networkFee?: string
  rawRampFee?: string
  rebateUsdAmount?: string
  payWayCode?: string
  rampFeeInUSD?: string
  rebateFiatAmount?: string
  cryptoNetworkFee?: string
}

export enum SupportPayWay {
  GOOGLEPAY = '701',
  APPLEPAY = '501',
  CREDITCARD = '10001'
}

export interface CurrencyItem {
  country: string
  currency: string
  payWayCode: string
  payWayName: string
  fixedFee: number
  feeRate: number
  payMin: number
  payMax: number
  countryName: string
  icon?: string
  payWay?: CurrencyItem[]
  cryptoDetail?: SupportCryptoItem | undefined
}

export interface SupportCryptoItem {
  crypto: string
  network: string
  buyEnable: number
  sellEnable: number
  minPurchaseAmount: number
  maxPurchaseAmount: number
  address: null | string
  icon: string
  minSellAmount: null | string | number
  maxSellAmount: null | string | number
}

export interface CryptoItem {
  currency: string
  country: string
  countryName: string
  fiatIcon: string
  buyEnable: number
  sellEnable: number
  minBuyFiatAmount: number
  maxBuyFiatAmount: number
  minSellCryptoAmount: number
  maxSellCryptoAmount: number
}

export interface CryptoReq {
  network: string
  crypto: string
}

export interface RequiredFieldsReq {
  fiat: string
  side: QuoteSide
  payWayCode: string
}

export interface RequiredFieldsRes {
  dataSource: any
  fields: {
    fieldName: string
    fieldType: string
    regex: string
    formElement: string
    dataSourceKey?: string
  }[]
}

export interface OrderCreateReq {
  side: QuoteSide
  merchantOrderNo: string
  cryptoCurrency: string
  address?: string
  network: string
  memo?: string
  fiatCurrency: string
  amount: string
  orderType: string
  payWayCode: string
  userAccountId?: string
  redirectUrl?: string
  callbackUrl?: string
  alpha2?: string
  extendParams: {
    cookie: string
    channelCookie: string
  }
  clientIp: string
}

export interface OrderCreateResponse {
  success: boolean
  returnMsg?: string
  data: OrderCreateRes | undefined
}

export interface OrderCreateRes {
  orderNo: string
  receivedAddress?: string
  webUrl?: string
}

export interface HistoryItem {
  address: string
  amount: string
  callbackUrl?: string
  cryptoCurrency: string
  fiatCurrency: string
  network: string
  orderNo: string
  orderType: string
  payWayCode: string
  quote: QuoteRes
  redirectUrl?: string
  side: QuoteSide
  userAccountId: string
  webUrl?: string
  status?: OnRampStatus
  createTime?: string
  tokenAddress?: string
  merchantOrderNo?: string
  txHash?: string
}

export interface GetOrderStatusReq {
  orderNo?: string
  merchantOrderNo?: string
  side: QuoteSide
}

export interface BuyStatusRes {
  side: QuoteSide
  amount: string
  orderNo: string
  address: string
  payTime: string
  txTime: string
  networkfee?: string
  networkFee: string
  crypto: string
  network: string
  cryptoPrice: string
  payType: string
  rampfee?: string
  rampFee: string
  cryptoQuantity: string
  appId: string
  fiat: string
  txHash: string
  status: string
  createTime: string
}

export interface SellStatusRes {
  orderNo: string
  address: string // wallet address user need to transfer
  payTime: string // transfer fiat start time
  completeTime: string // block confirm time
  merchantOrderNo: string
  crypto: string
  network: string
  cryptoPrice: string // fiat
  cryptoAmount: string
  fiatAmount: string //fiat amount contains ramp feee
  appId: string
  fiat: string
  txHash: string
  email: string
  signature: string
  status: string // 1：order create success  2：user transfered token  3：start to pay fiat to user  4：payment success 5：payment fail 6：refund success 7：order expired
  orderAddress: string // order detail page
  cryptoActualAmount: string //
  rampFee: string // fiat
  receiptTime: string //transfer fiat success time
  paymentType: string //  10001 banktransfer
  name: string // account name
  account: string // account number
  card: string // card No
  failReason: string // failReason
  createTime: string
}

export const enum OnRampStatus {
  PENDING = 'PENDING',
  PAY_FAIL = 'PAY_FAIL',
  PAY_SUCCESS = 'PAY_SUCCESS',
  TRANSFER = 'TRANSFER',
  CANCEL = 'CANCEL',
  FINISHED = 'FINISHED',
  RISK_CONTROL = 'RISK_CONTROL',
  REFUNDED = 'REFUNDED',
  INVALID_ADDRESS = 'INVALID_ADDRESS'
}

//!warning, ...new request type...
export interface OnrampToken {
  id: number
  network: string
  symbol: string
  buy: boolean
  icon: string
  maxBuySize: number
  maxSellSize: number
  minBuySize: number
  minSellSize: number
  open: boolean
  platformId: number
  sell: boolean
}

export interface PaymentFiatItem {
  code: string
  country: string
  countryName: string
  fee: number
  payMax: string
  payMin: string
  payName: string
  rate: number
}

export interface CountryListItem {
  buy: boolean
  country: string
  countryName: string
  icon: string
  id: number
  maxBuy: number
  maxSell: number
  minBuy: number
  minSell: number
  sell: boolean
  symbol: string
  paymentFiats: PaymentFiatItem[]
}

export interface CurrencyCyptoItem extends CountryListItem {
  cryptoDetail?: OnrampToken | undefined
}

export interface AccountIds {
  cardNumber: string
  id: number
  paymentType: string
  accountId: string
  billAddress: boolean
}

export interface CreateCard {
  address: string
  country: string
  countryName: string
  city: string
  state: string
  postCode: string

  cardNumber: string
  code: number
  cvv: string
  expirationData: string
  fiat: string
  firstName: string
  lastName: string
  paymentType: number
  side: string
}

export interface OrderCreateOnrampResponse {
  success: boolean
  msg?: string
  data: OrderCreateOnrampRes | undefined
}

export interface OrderCreateOnrampRes {
  fiat: string
  id: number
  orderNo: string
  payUrl: string
  status: number
  symbol: string
}

export interface OrderListReq {
  currentPage: number
  pageSize: number
}

export interface OnrampListItem {
  address: string
  amount: string
  createdAt: string
  fiat: string //EUR
  fiatSize: string // 100 EUR
  totalFiatSize: string
  hash: string
  id: number
  network: string //chain
  orderNo: string
  status: number
  symbol: string
}

export interface OnrampOrderCreateReq {
  clientOid: string
  memo: string
  orderTime: string
  userPaymentId: string
  address: string
  code: string
  depositType: string
  fiat: string
  network: string
  side: string
  size: string
  amount: string
  symbol: string
  channelCookie: string
  cookie: string
  clientIp: string
}

export interface CardFieldReq {
  code: number
  fiat: string
  network: string
  payCode: string
  side: string
  symbol: string
}

export interface BillCountryItem {
  alpha2: string
  alpha3: string
  enCommonName: string
  flag: string
  zhCommonName: string
}

export interface BillCityItem {
  alpha2: string
  name: string
  statesCode: string
}
